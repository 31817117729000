import React, {useState, useEffect} from "react";
import axios from "axios";
import {Formik} from "formik";

const Home = () => {

    const api_domain = "https://moebelwojtas.de";

    const [homeOffer, setHomeOffer] = useState([]);
    const [homeOfferDesc, setHomeOfferDesc] = useState([]);

    const fetchHomeOfferCall = async () => {
        let apiReturn = await axios
            .get(api_domain + "/meblewojtas/api/collections/get/Home_offer?token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(async function (response) {
                localStorage.setItem('home_offer', JSON.stringify(response.data.entries));
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    const fetchHomeOfferDescCall = async () => {
        let apiReturn = await axios
            .get(api_domain + "/meblewojtas/api/collections/get/Home_offer_desc?token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(async function (response) {
                localStorage.setItem('home_offer_desc', JSON.stringify(response.data.entries));
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    if (localStorage.getItem("home_offer") === null) {
        fetchHomeOfferCall();
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    if (localStorage.getItem("home_offer_desc") === null) {
        fetchHomeOfferDescCall();
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            fetchHomeOfferCall();
            fetchHomeOfferDescCall();
        }, 30000);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        setHomeOffer(JSON.parse(localStorage.home_offer));
        setHomeOfferDesc(JSON.parse(localStorage.home_offer_desc));
    }, [])

    return (
        <>
            <div className="home__slide">
                <div className="container">
                    <div className="col-12">
                        <div className="home__slide__inner">
                            <div className="home__slide__inner--text">
                                <span>Möbel für Sie gemacht</span>
                                <p>Hochwertiges Design und maximale Funktionalität – jeder Innenansicht verdient es.
                                    Dank sorgfältig durchdachter Lösungen helfen wir Ihnen, das Interieur Ihrer Träume
                                    zu schaffen.
                                </p>
                            </div>
                            <div className="home__slide__inner--line"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__about">
                <div className="container">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-12 my-auto">
                                <div className="home__about--text">
                                    <span>Über uns</span>
                                    <p>Seit vielen Jahren entwerfen und fertigen wir Küchenmöbel und Sonderanfertigungen
                                        nach Ihren Wünschen. Als Hersteller von maßgefertigten Möbeln verfügen wir über
                                        ein breites Spektrum an verfügbaren Lösungen, technologischen Einrichtungen und
                                        einem Team von Spezialisten, von Designern bis hin zu engagierten Monteuren. All
                                        dies ermöglicht es uns, die Erwartungen unserer Kunden zu erfüllen, indem wir
                                        zeitlose Möbel nach Maß herstellen.</p>
                                </div>
                                <div className="home__about--line"></div>
                            </div>
                            <div className="col-lg-6 col-12 my-auto">
                                <img
                                    src="https://meblewojtas.pl/meblewojtas/storage/uploads/2021/10/19/home_about_uid_616f3dbca6f86.png"
                                    loading="lazy" alt="" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__offer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="home__offer--heading">
                                <span>Angebot</span>
                            </div>
                        </div>
                        {
                            homeOffer.map((value: any, key: any) => {
                                let image = api_domain + Object.values(value.offer_image);
                                let desc = Object.values(value.offer_name);
                                let link = "";

                                if (key === 0) {
                                    link = "/gallery/61a8f042316635419b0000b5"
                                } else if (key === 1) {
                                    link = "/gallery/61a8f0ab363464288c000061"
                                } else if (key === 2) {
                                    link = "/gallery/61a8f0d630623195af000050"
                                } else if (key === 3) {
                                    link = "/gallery/61a8f153643733f9e7000171"
                                }

                                return (
                                    <div key={key} className="col-lg-6 col-12">
                                        <div className="home__offer_inner">
                                            <div className="home__offer_inner--text">
                                                <span>{desc}</span>
                                            </div>
                                            <div className="home__offer_inner--img">
                                                <a style={{cursor: 'pointer'}} onClick={() => {
                                                    window.location.href = link
                                                }}><img className={key === 0 ? 'btl-radius' : ''} loading="lazy"
                                                        src={image} alt=""></img></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            homeOfferDesc.map((value: any, key: any) => {
                                let title = Object.values(value.Title);
                                let content = Object.values(value.Content);
                                let image = api_domain + value.Image.path
                                return (
                                    <div key={key} className="col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-12 mt-4">
                                                <div className="home__offer_text">
                                                    <div className="home__offer_text--left">
                                                        <span>{title}</span>
                                                        <p>{content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt-4 my-auto">
                                                <div className="home__offer_inner">
                                                    <div className="home__offer_inner--img">
                                                        <img className="bbr-radius" src={image} loading="lazy"
                                                             alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="contact__top"></div>

            <div className="contact__box">
                <div className="container">
                    <div className="contact__box__inner">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="contact__box__inner--text mb-4">
                                            <span>Zeig uns deine Idee!</span>
                                            <p>Kontaktieren Sie sich mit uns über das Kontaktformular und wir werden so
                                                schnell wie möglich antworten.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Formik
                                            initialValues={{
                                                v_name_surname: "",
                                                v_phone: "",
                                                v_email: "",
                                                v_message: ""
                                            }}
                                            onSubmit={values => {
                                                axios.post('https://meblewojtas.pl/mail_api/send',
                                                    JSON.stringify(
                                                        {
                                                            name_surname: values.v_name_surname,
                                                            phone: values.v_phone,
                                                            email: values.v_email,
                                                            message: values.v_message
                                                        }
                                                    ),
                                                    {
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        }
                                                    }
                                                ).then((res: any) => {
                                                    if (res.data.code == 200) {
                                                        window.location.href = "/successfully";
                                                        window.scrollTo(0, 0);
                                                    }
                                                })
                                            }}
                                        >
                                            {
                                                ({
                                                     values,
                                                     handleChange,
                                                     handleSubmit,
                                                 }) => (
                                                    <form onSubmit={handleSubmit} className="contact__box__inner--form">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-12">
                                                                <div className="mb-4">
                                                                    <input onChange={handleChange} type="text"
                                                                           name="v_name_surname"
                                                                           className="form-control"
                                                                           placeholder="Name und Vorname" required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-12">
                                                                <div className="mb-4">
                                                                    <input onChange={handleChange} type="text"
                                                                           name="v_phone" className="form-control"
                                                                           placeholder="Mobiltelefon" required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-4">
                                                                    <input onChange={handleChange} type="text"
                                                                           name="v_email" className="form-control"
                                                                           placeholder="Email" required/>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-4">
                                                                    <textarea onChange={handleChange} name="v_message"
                                                                              className="form-control"
                                                                              placeholder="Inhalt der Nachricht"
                                                                              required></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-4">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input"
                                                                               type="checkbox" id="gridCheck"
                                                                               required></input>
                                                                        <label className="form-check-label"
                                                                               htmlFor="gridCheck">
                                                                            <small>Durch Klicken auf die Schaltfläche
                                                                                "Nachricht senden" stimmen Sie der
                                                                                Verarbeitung personenbezogener Daten
                                                                                gemäß dem Datenschutzgesetz im
                                                                                Zusammenhang mit dem Senden einer
                                                                                Anfrage über das Kontaktformular zu. Die
                                                                                Angabe von Daten ist freiwillig, aber
                                                                                notwendig, um die Anfrage zu bearbeiten.
                                                                                Ich wurde darüber informiert, dass ich
                                                                                das Recht habe, auf meine Daten
                                                                                zuzugreifen, sie zu korrigieren und die
                                                                                Beendigung ihrer Verarbeitung zu
                                                                                verlangen. Der Administrator der
                                                                                personenbezogenen Daten ist Meble
                                                                                Wojtas.</small></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">Nachricht
                                                            senden
                                                        </button>
                                                    </form>
                                                )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;
